<template>
    <div class="message-text">
        <div class="c-black" v-if="isFaq" v-html="$translate('ACTION_FAQ_OPEN_CHAT_NORMAL_BODY')" />
        <!-- <div class="c-black" v-else v-html="$translate('ACTION_OPEN_CHAT_NORMAL_BODY')" /> -->
        <div v-else-if="chat.agent_id === 3" class="open-chat">
            <div class="noti-title">
                <img class="m-r-8" src="@/assets/images/icons/hello.png" width="24px" height="24px" />
                <span>’굿퍼슨’에 오신 것을 <br />환영합니다!</span>
            </div>
            <!-- <div v-if="this.$store.getters.me.gender === 0 && dontShow === false" class="noti-body">
                • 매칭권을 구매하면, 여성 회원 서칭을 시작합니다.<br>
                • 회원님과 만날 의사가 있는 여성분을 찾은 후 프로필을 전달드리고, 회원님이 수락할 경우 만남 약속을 잡아드립니다.<br />
                • 만남이 성사되지 않는 경우 100% 환불해드립니다.
            </div>
            <div v-else-if="this.$store.getters.me.gender === 0 && dontShow === true" class="noti-body">
                • 여성 회원 서칭을 시작합니다.<br>
                • 회원님과 만날 의사가 있는 여성분을 찾은 후 프로필을 전달드리고, 회원님이 수락할 경우 만남 약속을 잡아드립니다.<br />
                • 만남이 성사되지 않는 경우 100% 환불해드립니다.
            </div>
            <div v-else-if="this.$store.getters.me.gender === 1 && dontShow === false" class="noti-body">
                • 매칭권을 구매하면, 남성 회원분들의 프로필을 회원님께 먼저 전달드립니다.<br />
                • 회원님이 수락하면, 남성분에게 의사를 묻고 만남 약속을 잡아드립니다.<br>
                • 내가 먼저 보고 선택한 사람만 내 프로필을 보기 때문에, 내 프로필이 불특정 다수에게 노출될 일이 없는 것이 굿퍼슨의 큰 장점입니다.
            </div>
            <div v-else-if="this.$store.getters.me.gender === 1 && dontShow === true" class="noti-body">
                • 남성 회원분들의 프로필을 회원님께 먼저 전달드립니다.<br />
                • 회원님이 수락하면, 남성분에게 의사를 묻고 만남 약속을 잡아드립니다.<br>
                • 내가 먼저 보고 선택한 사람만 내 프로필을 보기 때문에, 내 프로필이 불특정 다수에게 노출될 일이 없는 것이 굿퍼슨의 큰 장점입니다.
            </div> -->
            <div class="noti-body">
                안녕하세요,<br />
                고객센터는 회원분들이 서비스를 더욱 원활하게 이용하실 수 있도록 도움을 드리는 곳이에요 😄<br />
                프로필 작성과 서류 인증을 진행하면서 궁금하신 점은 언제든 편하게 이 곳에 남겨주시면 도움드릴게요!
            </div>
            <div class="noti-blue-box">
                <div>매니저 응대시간</div>
                <div class="noti-time">평일 오전 9시 ~ 오후 6시</div>
            </div>
            <!-- <div class="noti-body">
                <p class="m-b-16">
                    기다리시는 동안 매칭 선호 사항을 먼저 작성해주세요! 이상형 파악에 큰 도움이 됩니다.
                </p>
                <BottomButton
                    @click="onClickMatchPrefer"
                    label="매칭 선호사항 입력하기"
                    :black-btn="true"
                    :non-fixed="true"
                />
            </div> -->
        </div>
        <div v-else class="open-chat">
            <div class="noti-title">
                <img class="m-r-8" src="@/assets/images/icons/hello.png" width="24px" height="24px" />
                <span> {{ $store.getters.me.name }}님, 환영합니다.</span>
            </div>
            <!-- <div v-if="this.$store.getters.me.gender === 0 && dontShow === false" class="noti-body">
                • 매칭권을 구매하면, 여성 회원 서칭을 시작합니다.<br>
                • 회원님과 만날 의사가 있는 여성분을 찾은 후 프로필을 전달드리고, 회원님이 수락할 경우 만남 약속을 잡아드립니다.<br />
                • 만남이 성사되지 않는 경우 100% 환불해드립니다.
            </div>
            <div v-else-if="this.$store.getters.me.gender === 0 && dontShow === true" class="noti-body">
                • 여성 회원 서칭을 시작합니다.<br>
                • 회원님과 만날 의사가 있는 여성분을 찾은 후 프로필을 전달드리고, 회원님이 수락할 경우 만남 약속을 잡아드립니다.<br />
                • 만남이 성사되지 않는 경우 100% 환불해드립니다.
            </div>
            <div v-else-if="this.$store.getters.me.gender === 1 && dontShow === false" class="noti-body">
                • 매칭권을 구매하면, 남성 회원분들의 프로필을 회원님께 먼저 전달드립니다.<br />
                • 회원님이 수락하면, 남성분에게 의사를 묻고 만남 약속을 잡아드립니다.<br>
                • 내가 먼저 보고 선택한 사람만 내 프로필을 보기 때문에, 내 프로필이 불특정 다수에게 노출될 일이 없는 것이 굿퍼슨의 큰 장점입니다.
            </div>
            <div v-else-if="this.$store.getters.me.gender === 1 && dontShow === true" class="noti-body">
                • 남성 회원분들의 프로필을 회원님께 먼저 전달드립니다.<br />
                • 회원님이 수락하면, 남성분에게 의사를 묻고 만남 약속을 잡아드립니다.<br>
                • 내가 먼저 보고 선택한 사람만 내 프로필을 보기 때문에, 내 프로필이 불특정 다수에게 노출될 일이 없는 것이 굿퍼슨의 큰 장점입니다.
            </div> -->
            <div class="noti-body">
                앞으로 회원님을 담당할 퍼스널 매칭 매니저입니다. 잘 부탁드려요😊<br />
                굿퍼슨은 매칭이 되면 실제 만남 약속까지 잡아드리는 풀케어 서비스입니다!
            </div>
            <!-- <div class="noti-body">
                <p class="m-b-16">
                    기다리시는 동안 매칭 선호 사항을 먼저 작성해주세요! 이상형 파악에 큰 도움이 됩니다.
                </p>
                <BottomButton
                    @click="onClickMatchPrefer"
                    label="매칭 선호사항 입력하기"
                    :black-btn="true"
                    :non-fixed="true"
                />
            </div> -->
        </div>
    </div>
</template>

<script>
export default {
    name: 'ActionOpenChatNormal',
    props: ['message'],
    data: () => ({
        stats: [],
    }),
    computed: {
        actionOpenChatNonPro() {
            return this.$translate('ACTION_OPEN_CHAT_NON_PRO').replace(/%s/, this.chat.user.name)
        },
        chat() {
            return this.$store.getters.chat || {}
        },
        isFaq() {
            const chatUser = this.chat.user

            return chatUser.id === 2
        },
        dontShow() {
            // if (this.$isTester() || (this.$store.getters.device && this.$store.getters.device.os === 'ios')) {
            if (this.$isTester()) {
                return true
            } else {
                return false
            }
        },
    },
    methods: {
        // onClickMatchPrefer() {
        //     this.$stackRouter.push({
        //         name: 'EditStylePage',
        //     })
        // },
    },
}
</script>
<style scoped lang="scss">
.open-chat {
    padding: 9px 4px;
    .noti-title {
        @include spoqa-f-bold;
        font-size: 17px;
        color: #111111;
        padding: 0 2px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
    }
    .noti-body {
        font-size: 14px;
        margin-top: 8px;
        color: #111111;
        line-height: 26px;
    }
    .noti-blue-box {
        margin-top: 8px;
        background: #f0f8ff;
        border-radius: 8px;
        color: #111111;
        padding: 4px 8px;
        font-size: 14px;
        line-height: 24px;
        .noti-time {
            font-weight: bold;
            font-size: 16px;
            line-height: 26px;
        }
    }

    ::v-deep .bottom-button > button {
        height: 40px !important;
    }
}
</style>
